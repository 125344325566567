<template>
    <div class="container">
        <div class="search">
            <el-form>
                <el-form-item style="margin-bottom: 12px;">
                    <el-button size="small" type="primary"
                        ><router-link to="/iot/tsl/add"
                            >添加属性</router-link
                        ></el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <el-table
            border
            highlight-current-row
            :data="List"
            style="width: 100%"
            tooltip-effect="light"
            size="small"
        >
            <el-table-column
                fixed
                prop="identifier"
                label="属性"
                width="200px"
            ></el-table-column>
            <el-table-column prop="required" label="必填" width="70px">
                <template #default="scope">
                    <el-switch
                        disabled="false"
                        v-model="scope.row.required"
                        :active-value="1"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="writable" label="可写" width="70px">
                <template #default="scope">
                    <el-switch
                        disabled="false"
                        v-model="scope.row.writable"
                        :active-value="1"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="data_type"
                label="字段类型"
                width="80px"
            ></el-table-column>
            <el-table-column
                prop="default"
                label="默认值"
                :show-overflow-tooltip="true"
            >
                <template #default="scope" v-slot="scope">
                    <template v-if="scope.row.default.length > 10">
                        <el-button
                            type="primary"
                            size="small"
                            @click="OpenDefaultValue(scope.row.default)"
                            plain
                            >查看默认值</el-button
                        >
                    </template>
                    <template v-else>
                        {{ scope.row.default }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="desc"
                label="说明"
                width="280px"
                :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="规则" width="120px">
                <template #default="scope" v-slot="scope">
                    <el-button
                        type="primary"
                        size="small"
                        @click="
                            OpenAttributeRules(
                                scope.row.data_type,
                                scope.row.specs
                            )
                        "
                        plain
                        >查看规则
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
                <template #default="scope">
                    <el-popconfirm
                        title="你确定要删除此属性吗?"
                        confirm-button-text="是"
                        cancel-button-text="否"
                        @confirm="DelAttr(scope.row)"
                    >
                        <template #reference>
                            <el-button type="danger" size="mini"
                                >删除</el-button
                            >
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="searchForm.rows"
            :current-page="searchForm.page"
            :total="searchForm.total"
        />
    </div>

    <el-dialog v-model="RuleDetailsVisible">
        <template #title>
            <div class="my-header" style="text-align: center;">
                <h4>数据规则</h4>
            </div>
        </template>
        <arrayStructAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'array'"
            :specs="RuleDetailsForm.specs"
        ></arrayStructAttr>
        <structAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'struct'"
            :specs="RuleDetailsForm.specs"
        ></structAttr>
        <intAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'int'"
            :specs="RuleDetailsForm.specs"
        ></intAttr>
        <boolAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'bool'"
            :specs="RuleDetailsForm.specs"
        ></boolAttr>
        <textAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'text'"
            :specs="RuleDetailsForm.specs"
        ></textAttr>
        <enumAttr
            onlyRead
            v-if="RuleDetailsForm.type == 'enum'"
            :specs="RuleDetailsForm.specs"
        ></enumAttr>
    </el-dialog>

    <el-dialog v-model="showDefaultValueVisible">
        <template #title>
            <div class="my-header" style="text-align: center;">
                <h4>默认值</h4>
            </div>
        </template>
        <b>
            <pre>{{ showDefaultValue }}</pre>
        </b>
    </el-dialog>
</template>

<script>
import intAttr from '../../../components/tsl/attribute/int.vue'
import boolAttr from '../../../components/tsl/attribute/bool.vue'
import enumAttr from '../../../components/tsl/attribute/enum.vue'
import textAttr from '../../../components/tsl/attribute/text.vue'
import structAttr from '../../../components/tsl/attribute/struct.vue'
import arrayStructAttr from '../../../components/tsl/attribute/array_struct.vue'
export default {
    components: {
        intAttr,
        boolAttr,
        enumAttr,
        textAttr,
        structAttr,
        arrayStructAttr
    },
    name: 'TslAttributes',
    mixins: [],
    data() {
        return {
            List: [],
            RuleDetailsVisible: false,
            RuleDetailsForm: {},
            showDefaultValueVisible: false,
            showDefaultValue: '',
            searchForm: {
                page: 1,
                rows: 9,
                total: 0
            }
        }
    },
    mounted() {
        this.GetList()
    },
    methods: {
        handleCurrentChange: function(val) {
            this.searchForm.page = val
            this.GetList()
        },
        OpenAttributeRules: function(type, item) {
            this.RuleDetailsForm = {
                type: type,
                specs: item
            }
            console.info(JSON.stringify(this.RuleDetailsForm))
            this.RuleDetailsVisible = true
        },
        GetList: function() {
            this.api.TslAttributeList(this.searchForm).then((res) => {
                if (res.data.code == 200) {
                    this.List = res.data.data.data
                    this.searchForm.total = res.data.data.page_info.total
                }
            })
        },
        OpenDefaultValue: function(item) {
            this.showDefaultValue = JSON.stringify(item, null, 4)
            this.showDefaultValueVisible = true
        },
        DelAttr: function(row) {
            console.log('DelAttr: ', row)
            this.api.TslAttributeDelete({ id: row.id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('删除成功')
                    this.GetList()
                    return
                }
                this.$message.error('调用失败:' + res.data.msg)
            })
        }
    }
}
</script>

<style></style>
