<template>
	<el-form :disabled="onlyRead">
		<el-form-item label="数据类型:" label-width="120px">
			<el-tag>Bool</el-tag>
		</el-form-item>
		<el-form-item v-for="(val,key) of specs" :label="val+':'" label-width="120px">
			<el-input :model-value="key" ></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		name: 'bool',
		mixins: [],
		props: ['specs', 'onlyRead'],
		data() {
			return {}
		}
	}
</script>