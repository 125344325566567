<template>
	<el-tabs tab-position="left">
		<el-tab-pane v-for="item in specs" :label="item.identifier">
			<intAttr :onlyRead="onlyRead" :specs="item.dataType.specs" v-if="item.dataType.type =='int'"></intAttr>
			<boolAttr :onlyRead="onlyRead" :specs="item.dataType.specs" v-if="item.dataType.type =='bool'"></boolAttr>
			<textAttr :onlyRead="onlyRead" :specs="item.dataType.specs" v-if="item.dataType.type =='text'"></textAttr>
			<enumAttr :onlyRead="onlyRead" :specs="item.dataType.specs" v-if="item.dataType.type =='enum'"></enumAttr>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	import intAttr from '../../../components/tsl/attribute/int.vue'
	import boolAttr from '../../../components/tsl/attribute/bool.vue'
	import enumAttr from '../../../components/tsl/attribute/enum.vue'
	import textAttr from '../../../components/tsl/attribute/text.vue'
	import structAttr from '../../../components/tsl/attribute/struct.vue'
	export default {
		name: 'arrayStruct',
		mixins: [],
		components: {
			intAttr,
			boolAttr,
			enumAttr,
			textAttr,
			structAttr
		},
		props: ['specs', 'onlyRead'],
		data() {
			return {
				dataType: {
					'int': "数值",
					'bool': "布尔值",
					'enum': "枚举",
					'text': "字符串",
					'struct': "对象"
				}
			}
		}
	}
</script>

<style>
</style>
