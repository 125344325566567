<template>
	<el-form :disabled="onlyRead">
		<el-form-item label="数据类型:" label-width="120px">
			<el-tag>数字</el-tag>
		</el-form-item>
		<el-form-item label="最小值:" label-width="120px">
			<el-input v-model="specs.min"></el-input>
		</el-form-item>
		<el-form-item label="最大值:" label-width="120px">
			<el-input v-model="specs.max"></el-input>
		</el-form-item>
		<el-form-item label="步长:" label-width="120px">
			<el-input v-model="specs.step"></el-input>
		</el-form-item>
		<el-form-item label="单位:" label-width="120px">
			<el-input v-model="specs.unit"></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		name: 'int',
		mixins: [],
		props: ['specs','onlyRead'],
		data() {
			return {}
		}
	}
</script>

<style>
</style>
